.reply-modal {
    max-width: 40vw; /* Adjusts for smaller screens */
    margin: auto;
    color: var(--text_color);
    overflow: hidden;
}

.reply-modal-content {
    background-color: hsl(var(--status_background));
    color: var(--text_color);
    width: 100%;
    max-height: 80vh;
    overflow-y: auto;
    padding: 15px; /* Added padding for better readability */
}

.reply-modal-content input, 
.reply-modal-content textarea {
    background-color: hsl(var(--status_background));
    color: var(--text_color);
    border: none;
    width: 100%;
    padding: 10px; /* Adjust padding for touch devices */
    resize: none;
}

.reply-bottom {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 10px;
    font-size: 0.9em; /* Adjusted font size for smaller screens */
}

.mini-status {
    padding: 15px;
    margin-bottom: 15px;
    background-color: hsl(var(--body_background));
    border-radius: 8px;
    box-shadow: var(--box_shadow);
    width: 100%;
    max-height: 30vh;
    overflow-y: auto;
}

.share-body input {
    background-color: hsl(var(--body_background));
    padding: 10px;
}

.share-buttons {
    display: flex;
    align-items: center;
    gap: 10px;
}

/* Mobile adjustments */
@media (max-width: 1024px) {
    .reply-modal {
        max-width: 80vw;
    }

    .reply-modal-content {
        max-height: 70vh;
        padding: 10px;
    }

    .reply-bottom {
        font-size: 0.8em;
    }

    .mini-status {
        padding: 10px;
    }
}
