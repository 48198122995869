
/* styles.css */
.modal-custom {
    color: black;
    background-color: white;
  }
  
.toast-custom {
    background-color: white;
    color: black;
}

.toast-header-custom {
    background-color: white;
    color: black;
}
  