.theme{
    width: 60%;
    height: 60%;
    padding: 20px;
    margin: 5% auto;
    background-color: hsl(var(--status_background));
    border-radius: 1.5%;
    box-shadow: var(--box_shadow);
    color: var(--text_color);
}

.t-drop{
    padding: 0 10px;
}
  
.color-picker:focus {
    outline: none;
}
  
.color-picker{
    appearance: none;
    -webkit-appearance: none;
    outline: none;
    height: 8px;
    width: 100%;
    box-shadow: 0 0 0.8em none; 
    border-radius: 100vmax;
    background: linear-gradient(to right,
        hsl(0, 50%, 50%),
        hsl(25, 50%, 50%),
        hsl(50, 50%, 50%),
        hsl(75, 50%, 50%),
        hsl(100, 50%, 50%),
        hsl(125, 50%, 50%),
        hsl(150, 50%, 50%),
        hsl(175, 50%, 50%),
        hsl(200, 50%, 50%),
        hsl(225, 50%, 50%),
        hsl(250, 50%, 50%),
        hsl(275, 50%, 50%),
        hsl(300, 50%, 50%),
        hsl(325, 50%, 50%),
        hsl(350, 50%, 50%));
}

.color-container {
    padding: 20px;
    height: calc(100%-1px);
    width: 100%;
    margin: auto;
    font-family: Arial, sans-serif;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}

.color-form{
    display: flex;
    flex-direction: column;

}

.button-group{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
    width: 240px;
    background-color: hsl(var(--button_color));
    text-shadow: var(--text_shadow)
}

.button-group-tab{
    height: 100%;
    width: 80px;
    background-color: hsl(var(--button_color));
    border: 1px solid black;
    text-align: center;
}
.button-group-tab:hover{
    filter: brightness(85%);
}

.selected{
    height: 100%;
    width: 80px;
    background-color: hsl(var(--button_color));
    filter: brightness(65%);
    border: 1px solid black;
    text-align: center;
}

.color-form {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.color-inputs {
    display: flex;
    flex-direction: column;
    gap: 15px;
    margin: 20px;
}

.color-inputs label {
    display: flex;
    align-items: center;
    gap: 5px;
}

.color-inputs input {
    width: 100px;
    height: 40px;
    padding: 5px;
    font-size: 16px;
    text-align: center;
    border: 2px solid #ccc;
    border-radius: 5px;
    outline: none;
    transition: border-color 0.3s;
}

.color-inputs input:focus {
    border-color: #007BFF;
}

.hex-input {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 5px;
}

.hex-input input {
    width: 120px;
    height: 40px;
    padding: 5px;
    font-size: 16px;
    text-align: center;
    border: 2px solid #ccc;
    border-radius: 5px;
    outline: none;
    transition: border-color 0.3s;
}

.hex-input input:focus {
    border-color: #007BFF;
}

.color-display {
    margin-top: 20px;
    width: 150px;
    height: 150px;
    border: 2px solid #000;
    border-radius: 10px;
}

@media (max-width: 1024px) {
    .theme {
        width: 80%;
        height: auto;
        padding: 15px;
    }

    .button-group {
        max-width: 250px;
    }

    .color-display {
        width: 120px;
        height: 120px;
    }
}

@media (max-width: 768px) {
    .theme {
        padding: 10px;
        margin: auto;
    }

    .button-group {
        margin-top: 10px;
    }

    .color-container {
        flex-direction: column;
        align-items: center;
    }

    .color-display {
        width: 100px;
        height: 100px;
    }
}

@media (max-width: 480px) {
    .theme {
        width: 95%;
        padding: 5px;
        margin: auto;
    }

    .button-group-tab {
        padding: 8px;
        font-size: 14px;
    }

    .color-inputs input {
        width: 100%;
        font-size: 14px;
        height: 35px;
    }

    .color-display {
        width: 80px;
        height: 80px;
    }
}
