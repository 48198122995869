.navbar{
    background-color: hsl(var(--status_background));
    color: var(--text_color);
    box-shadow: var(--box_shadow);
    position: sticky;
    
}
  
.navbar li{
    color: var(--text_color);
}
  
.navbar li:hover{
    cursor: pointer;
}

.navbarProfileImg {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  object-fit: cover;
}
.navbarProfileImg:hover{
  cursor: pointer;
}

.container-fluid {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

/*little sus*/
@media (max-width: 768px) { /* Tablet and below */
  .container-fluid {
    flex-direction: row;
    align-items: flex-start; /* Stack elements vertically */
  }
}

 /* Dropdown Button */
 .dropbtn {
    background-color: #04AA6D;
    color: white;
    padding: 16px;
    font-size: var(--heading);
    border: none;
  }
  
  /* The container <div> - needed to position the dropdown content */
  .dropdown {
    position: relative;
    display: inline-block;
  }
  
  /* Dropdown Content (Hidden by Default) */
  .dropdown-content {
    display: block;
    position: absolute;
    right: 0;
    background-color: hsl(var(--body_background));
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
  }
  
  /* Links inside the dropdown */
  .dropdown-content div {
    color: var(--text_color);
    padding: 12px 16px;
    text-decoration: none;
    display: block;
  }
  
  /* Change color of dropdown links on hover */
  .dropdown-content div:hover {background-color: hsl(var(--status_background));}
  
  /* Show the dropdown menu on hover */
  .dropdown:hover .dropdown-content {display: block;}

.vikalp{
  margin-right: 20px;
}

.vikalpImg {
  width: 30px;
  height: 30px;
  object-fit: cover;
}
.vikalpImg:hover{
  cursor: pointer;
}

.navbar-right{
  display: flex;
  align-items: center;
}

.mystodon {
  flex-grow: 1; /* Ensure it takes up space between left and right sections */
  text-align: center; /* Center the text */
  font-size: 1.2rem; /* Adjust font size as needed */
  font-weight: bold; /* Optional: Make the font bold */
  margin-left: -17%;
  cursor: pointer;
}

.search-icon{
  display: none;
}

/* Responsive adjustments */
@media (max-width: 876px) {
  /* Hide the search form and show the search icon */
  .navbar .search-form {
      display: none;
  }

  /* Show search icon */
  .search-icon {
      display: block;
      font-size: 1.2rem;
      cursor: pointer;
  }

  /* Show search bar on icon click */
  .search-form.active {
      display: flex;
      position: absolute;
      top: 60px;
      left: 0;
      right: 0;
      padding: 10px;
      background-color: hsl(var(--status_background));
      box-shadow: var(--box_shadow);
  }

  .mystodon{
    margin: 0;
  }
}
