.sidebar{
    margin-left: 5%;
    margin-top: 20px;
    width: 25%;
    background-color: hsl(var(--status_background));
    border-radius: 1.5%;
    position: fixed;
    box-shadow: var(--box_shadow);
    padding: 20px;
}

.sidebarTop {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.sidebarTopLeft {
	display: flex;
	align-items: center;
}

.sidebarUser{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    font-size: var(--subheading);
}

.sidebarCenter{
	display: flex;
	flex-direction: column;
}
  
.sidebar img{
    width: 18%;
    height: 18%;
    border-radius: 50%;
    object-fit: cover;
}
  
.sidebar textarea{
    background-color: hsl(var(--body_background));
    color: var(--text_color);
    border: none;
}

.sidebar-button {
    display: none;
    position: fixed;
    height: 50px;
    width: 50px;
    bottom: 1rem;
    right: 1rem;
    background-color: #007BFF;
    color: #fff;
    border: none;
    border-radius: 50%;
    font-size: 1.5rem;
    cursor: pointer;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.3);
}

@media (max-width: 1024px) {
    /* Sidebar as a single bar at the top */
    .sidebar {
        display: none; 
    }

    .sidebar-button {
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 10;
    }

    /* Adjust the textarea for mobile */
    .sidebar textarea {
        width: 80%;
        margin: 0;
        font-size: var(--text);
    }

    /* Hide any unnecessary elements like avatar and user info */
    .sidebar .sidebarTop {
        display: none;
    }

    /* Remove file upload input */
    .sidebar .mb-3 input[type="file"] {
        display: none;
    }

    /* Button styling for mobile */
    .my-button {
        width: 80%;
        margin-top: 10px;
    }
}
