.switch {
    width: 60%;
    min-height: 60%;
    padding: 20px;
    margin: 10vh auto; /* Centering horizontally */
    background-color: hsl(var(--status_background));
    border-radius: 1.5%;
    box-shadow: var(--box_shadow);
    color: var(--text_color);
    display: flex;
    flex-direction: column;
    gap: 50px;
}

/* List section with horizontal scrolling on smaller screens */
.list {
    display: flex;
    justify-content: flex-start;
    gap: 20px;
    margin: 10px 0;
    overflow-x: auto; /* Enables horizontal scroll */
}

/* Custom button style with consistent size */
.custom-button {
    background-color: hsl(var(--button_color));
    border: none;
    border-radius: 10px;
    font-size: calc(var(--text) * 1.2);
    padding: 30px 20px;
    cursor: pointer;
    box-shadow: var(--box_shadow);
    min-width: 150px; /* Consistent button width on smaller screens */
    max-width: 180px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
}

/* Responsive styling for smaller screens */
@media (max-width: 768px) {
    .switch {
        width: 80%;
        margin: 5vh auto; /* Reduced top margin */
    }

    .custom-button {
        min-width: 120px; /* Slightly smaller for mobile */
        padding: 20px 15px; /* Adjust padding for mobile */
    }
}
