.search{
    padding: 20px;
    margin-bottom: 10px;
    background-color: hsl(var(--status_background));
    border-radius: 1.5%;
    box-shadow: var(--box_shadow);
    width: 100%;
}

.search-options{
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin-bottom: 10px;
    width: 100%;
    font-size: var(--text);
}
  
.search-options div {
    width: 50%;
    padding: 10px;
    cursor: pointer;
    background-color: hsl(var(--status_background));
    border-bottom: 5px solid hsl(var(--status_background));
    color: grey;
    text-align: center;
}

.search-options div:hover {
    color: var(--text_color);
}

.search-options .active-option {
    color: var(--text_color);
    border-bottom: 5px solid rgb(16,179,209);
}