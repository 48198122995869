.profile{
    background-color: hsl(var(--status_background));
    color: var(--text_color);
    border-radius: 1.5%;
    margin-bottom: 20px;
    position: relative;
    overflow: auto;
    z-index: 1;
    display: flex;
    flex-direction: column;
	box-shadow: var(--box_shadow);
	width: 100%;
	padding: 30px;
	gap: 10px;
}
  
.profileTop {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.header-container{
	position: relative;
  	text-align: center;
}

.profileHeader{
	width: calc(100% + 60px); /* twice the value of the parent's padding*/
    margin-left: -30px;
	margin-top: -30px;
	height: 200px;
	object-fit: cover;
}

.followed-by{
	position: absolute;
	top: -20px;
  	left: -20px;
	background-color: rgb(193,194,194);
	color: rgb(22,28,31);
	font-size: var(--subheading);
	font-weight: 600;
	padding: 5px;
	border-radius: 8%;
}

.profileTopLeft {
	display: flex;
	align-items: center;
}

.profileImg {
	width: 80px;
	height: 80px;
	border-radius: 50%;
	object-fit: cover;
	margin-top: 10px;
}

.user{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.profileUsername {
	font-size: var(--heading);
	font-weight: 500;
}
.profileUsername:hover {
	text-decoration: underline;
	cursor: pointer;
}

.profileUserInstance{
	font-size: var(--subheading);
}

.profileStats{
	padding-top: 0;
}

.profileStats .stats{
	color: rgb(4,117,194);
}

.profileStats .follow:hover{
	text-decoration: underline;
	cursor: pointer;
}

.profileCenter{
	display: flex;
	flex-direction: column;
}

.profileMedia{
	margin-top: 20px;
	width: 100%;
	max-height: 500px;
	object-fit: contain;
}

.editProfile{
	background-color: hsl(var(--status_background));
	color: var(--text_color);
}

.editProfile input{
	background-color: hsl(var(--body_background));
	color: var(--text_color);
}

.editProfile textarea{
	background-color: hsl(var(--body_background));
	color: var(--text_color);
}